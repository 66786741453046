<!-- 页面底部注解 -->
<template>
  <div class='annotation'>
    <p>{{ hintText }}</p>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    languageType: {
      type: [String],
      default: 'ZN',
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hintText: '',
    }
  },
  mounted() {
    if (this.hint == '') {
      if (this.languageType == 'ZN') {
        this.hintText = '注：该页面仅包括综合电商销售数据；免责声明见第11页。'
      } else {
        this.hintText =
          'Note: This page includes sales data from traditional online retailer platforms only; Please find the disclaimer on P11.'
      }
    } else {
      this.hintText = this.hint
    }
  },
}
</script>
<style lang='scss' scoped>
@import '@/style/rem.scss';
.annotation {
  width: 100vw;
  height: rem(40);
  font-size: rem(18);
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: rem(40);
  color: #ffffff;
  text-align: left;
  text-indent: rem(20);
}
</style>