<!-- 小标题带icon -->
<template>
  <div :class="['sub-title',languageType]">
    <img class="icon-title" src="@/assets/speaial_activity_img/image/icon.png">
    <slot>
      <p>{{ title }}</p>
    </slot>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    title: {
      type: String,
      default: '标题',
    },
    languageType: {
      type: String,
      default: 'ZN',
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
@import '@/style/rem.scss';
.sub-title {
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
  .icon-title {
    height: rem(16);
    margin-right: rem(4);
  }
}
.ZN {
  display: flex;
  align-items: center;
  p {
    font-size: rem(28);
  }
}
.EN {
  display: flex;
  align-items: center;
  .icon-title {
    // margin-top: rem(12);
    margin-right: rem(10);
  }
  p {
    font-size: rem(24);
    line-height: rem(26);
  }
}
</style>