<template>
  <div :class="['header',styleSize]">
    <img :src="logo" class="logo" />
    <!-- 其他页面的头 -->
    <div class="title-view zn flex-1 flex jc-c ai-c" v-if="language=='ZN'">
      <img class="title" :src="styleSize == 'default'?title:titleMin" />
    </div>
    <div v-else class="title-view en flex-1 flex jc-c ai-c">
      <img class="title" :src="styleSize == 'default'?titleEn:titleMinEn" />
    </div>
    <div class="action flex ai-c zn" v-if="language=='ZN'">
      <div class="time">2023年11月11日</div>
      <img src="@/assets/speaial_activity_img/image/lang-cn.png" class="language" @click="changeLanguage('EN')">
    </div>
    <div class="action flex ai-c en" v-else>
      <div class="time">11/11/2023</div>
      <img src="@/assets/speaial_activity_img/image/lang-en.png" class="language" @click="changeLanguage('ZN')">
    </div>
  </div>
</template>

<script>
import { bus } from '../../main.js'
export default {
  props: {
    times: {
      type: String,
      default: '0:00--24:00', //vertical
    },
    styleSize: {
      type: String,
      default: 'default',
    },
    languageType: {
      type: String,
      default: 'ZN',
    },
  },
  data() {
    return {
      logo: require('@/assets/speaial_activity_img/image/logo.png'),
      title: require('@/assets/speaial_activity_img/image/home_title.png'),
      titleEn: require('@/assets/speaial_activity_img/imageEn/home_title.png'),
      titleMin: require('@/assets/speaial_activity_img/image/content_title.png'),
      titleMinEn: require('@/assets/speaial_activity_img/imageEn/content_title.png'),
      language: 'ZN',
    }
  },
  components: {},
  created() {
    this.language = this.languageType
  },
  watch: {},
  methods: {
    changeLanguage(value) {
      this.language = value
      bus.$emit('languageSwitch', value)
    },
    closePage() {
      bus.$emit('closePage')
    },
  },
}
</script>
  
<style lang="scss" scoped>
@import '@/style/rem.scss';
.header {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 0 rem(30);
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  .title-view {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    left: 0;
    top: 0;
    .title {
      width: 100%;
      height: 100%;
    }
  }
  .action {
    z-index: 1;
  }
  .logo {
    z-index: 1;
  }
  .time {
    color: #fff;
    font-family: 'Source Han Sans CN';
    font-weight: 400;
    white-space: nowrap;
    line-height: 1;
  }
}

.default {
  height: rem(130);
  align-items: center;
  .logo {
    height: rem(80);
  }
  .time {
    font-size: rem(32);
    margin-right: rem(24);
    height: rem(24);
  }
}
.min {
  height: rem(98);
  padding-top: rem(10);
  .logo {
    width: rem(174);
    height: rem(64);
  }
  .time {
    font-size: rem(28);
    margin-right: rem(45);
    height: rem(28);
  }
  .action {
    height: rem(58);
  }
}
.language {
  cursor: pointer;
  width: rem(36);
  height: rem(36);
  z-index: 99;
}
.close-page {
  font-size: rem(40);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  margin-left: rem(10);
}
</style>
