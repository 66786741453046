<!--  -->
<template>
  <div class="title-view flex jc-sb ai-c">
    <div class="title-group flex ai-c">
      <img v-if="isShwoIcon" src="@/assets/speaial_activity_img/image/icon_title.png" class="icon" alt="">
      <slot></slot>
    </div>
    <div :class="['title-time',languageType]">{{ time }}</div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    time: {
      type: String,
      default: '数据时间',
    },
    imgSrc: {
      type: String,
    },
    languageType: {
      type: String,
      default: 'ZN',
    },
    isShwoIcon: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
@import '@/style/rem.scss';
.title-view {
  width: 84%;
  .icon {
    height: rem(30);
    margin-right: rem(10);
  }
  .title {
    height: rem(30);
  }
  .title-time {
    font-size: rem(28);
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
  }
  .EN {
    font-size: rem(24);
    text-align: left;
    color: #ffffff;
    font-family: Source Han Sans CN;
    font-weight: 500;
    opacity: 0.85;
  }
}
</style>